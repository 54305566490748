import { createContext, useEffect, useState } from 'react'
import { ItemContextType } from '../types/ItemContextType'
import Element from '../components/Element'
import Item from '../types/Item'
import * as valueUtils from '../utils/valueUtils'

export const ItemContext = createContext<ItemContextType>({
  item: {},
  setItem: () => {
    throw new Error('Function not implemented.')
  },
  initEmptyValues: () => {
    throw new Error('Function not implemented.')
  },
  refreshEmptyValue: () => {
    throw new Error('Function not implemented.')
  }
})

interface ItemProviderProps {
  layout: any
  item: Item
}

export const ItemProvider: React.FC<ItemProviderProps> = ({ layout, item }) => {
  // Вебийн тохиргоог агуулна.
  const [_item, setItem] = useState<Item>()
  // Хуудас солигдох үед мэдээллийг шинээр дүрсэлнэ.
  useEffect(() => {
    setItem(item)
  }, [item])

  const initEmptyValues = () => {
    const fields: any[] = []
    const result = { ...item }
    const travers = (current: any) => {
      const type = valueUtils.getValue(current, 'type')
      if (type < 1000) {
        fields.push(current)
      }
      if (current.items) {
        current.items.forEach(travers)
      }
    }
    travers(layout)
    fields.forEach(current => {
      if (_item !== undefined && _item.values && result.values) {
        const multi = valueUtils.getValue(current, 'multi')
        // Бүх утгаар дамжина.
        const fieldValues = _item.values.filter((value: any) => value.field === current.id)
        const emptyValue = fieldValues.find((value: any) => value.value == null || value.value === '')
        if (fieldValues.length === 0 || (multi && emptyValue == null)) {
          result.values.push({ field: current.id, value: null })
        }
      }
    })
  }
  // Утгагүй бол хоосон утга нэмнэ,
  // хэрвээ олон утга авдаг бөгөөд олон хоосон утгатай бол
  // нэг л хоосон утга үлдээнэ.
  const refreshEmptyValue = (properties: any) => {
    const multi = valueUtils.getValue(properties, 'multi')
    // Бүх утгаар дамжина.
    setItem((prev: any) => {
      const fieldValues = prev.values.filter((value: any) => value.field === properties.id)
      const emptyValue = fieldValues.find((value: any) => value.value == null || value.value === '')
      if (fieldValues.length === 0 || (multi && emptyValue == null)) {
        return { ...prev, values: [...prev.values, { field: properties.id, value: null }] }
      } else {
        return { ...prev, values: prev.values.filter((value: any) => value) }
      }
    })
  }

  const value = { item: _item, setItem, initEmptyValues, refreshEmptyValue }
  // Модал цонх дүрслэх үед _item хоосон утгатай байх тул утга байгаа эсэхийг шалгав.
  return _item && <ItemContext.Provider value={value}>
    <Element properties={layout} />
  </ItemContext.Provider>
}
