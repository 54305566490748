import { FC } from 'react'
import { getValue } from '../../utils/valueUtils'

let parentNode: any
let draggedNode: any
let rootNode: any
/**
 * Драг дроп хийх боломжтой болгоно
 * @param param0 css класс, үндсэн зангилаа, дүрслэх элэементүүдийг дамжуулна
 * @returns 
 */
const Builder: FC<BuilderProps> = ({ className, root, onChange, renderNode }) => {
    const handleChange = () => {
        onChange(root?.items?.length > 0 ? root.items[0] : null)
    }
    return <Nodes className={className} nodes={root?.items} root={root} onChange={handleChange} renderNode={renderNode} />
}

const Nodes: FC<NodesProps> = ({ className, nodes, root, onChange, renderNode }) => {

    const onDragOver = (ev: any) => {
        ev.preventDefault()
    }

    const onDrop = (ev: any) => {
        // Давхар тагийн давхар дуудалтыг зогсооно.
        ev.stopPropagation()
        // Чирж байгаа зангилааны дугаарыг авна.
        // const id: number = Number(ev.dataTransfer.getData("id"))
        if (draggedNode && parentNode) {
            // Мод дотроо зөөсөн эсэхийг шалгана.
            if (rootNode === root) {
                // Мод дотроо зөөсөн бол өмнөх байрлалаас устгана.
                parentNode.items.splice(parentNode.items.indexOf(draggedNode), 1)
            } else {
                // Мод хооронд зөөсөн бол хувилна.
                draggedNode = structuredClone(draggedNode)
            }
            // Зангилааг шинэ байрлалд оруулна.
            nodes.push(draggedNode)
            // өөрчлөлтийг дэлгэцэнд дүрсэлнэ.
            onChange && onChange()
        }
        ev.target.classList.remove('drag-over')
        draggedNode = null
        parentNode = null
    }

    const onDragEnter = (ev: any) => {
        ev.target.classList.add('drag-over')
    }

    const onDragLeave = (ev: any) => {
        ev.target.classList.remove('drag-over')
    }
    return <ol className={className} onDragOver={onDragOver} onDrop={onDrop} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
        {nodes?.map((node: any, i) =>
            <Node key={i} node={node} nodes={nodes} root={root} onChange={onChange} renderNode={renderNode} />)}
    </ol>
}
const Node: FC<NodeProps> = ({ node, nodes, root, onChange, renderNode }) => {

    const onDragStart = (ev: any, node: any) => {
        // Давхар тагийн давхар дуудалтыг зогсооно.
        ev.stopPropagation()
        ev.dataTransfer.setData("id", node.id)
        draggedNode = node
        parentNode = findParentNode(root, node)
        rootNode = root
    }

    const onDragOver = (ev: any) => {
        ev.preventDefault()
    }

    const onDrop = (ev: any) => {
        // Давхар тагийн давхар дуудалтыг зогсооно.
        ev.stopPropagation()
        if (draggedNode && parentNode) {
            const index = parentNode.id === node.id && nodes.indexOf(node) < nodes.indexOf(draggedNode) ?
                nodes.indexOf(node) : nodes.indexOf(node) + 1
            // Мод дотроо зөөсөн эсэхийг шалгана.
            if (rootNode === root) {
                // Мод дотроо зөөсөн бол өмнөх байрлалаас устгана.
                parentNode.items.splice(parentNode.items.indexOf(draggedNode), 1)
            } else {
                // Мод хооронд зөөсөн бол хувилна.
                draggedNode = structuredClone(draggedNode)
            }
            // Зангилааг шинэ байрлалд оруулна.
            nodes.splice(index, 0, draggedNode)
            // Өөрчлөлтийг мэдэгдэнэ.
            onChange && onChange()
        }
        draggedNode = null
        parentNode = null
    }
    const type = getValue(node, 'type')
    let className = ''
    if (type === 5002) {
        const size = getValue(node, 'size') ?? '1/4'
        className += ' e' + type + ' col-' + size.replace('/', '-')
    } else {
        className += ' e' + type
    }
    const onRemove = () => {
        nodes.splice(nodes.indexOf(node), 1)
        onChange()
    }
    return <li key={node} draggable onDragStart={(e) => onDragStart(e, node)}
        onDragOver={onDragOver} onDrop={onDrop} className={className}>
        <>
            {renderNode(node, onRemove)}
            {node.items && <Nodes root={root} nodes={node.items} onChange={onChange} renderNode={renderNode} />}
        </>
    </li>
}
const findParentNode: any = (root: any, node: any) => {

    const find: any = (parent: any, child: any) => {
        if (node === child) {
            return parent
        }
        if (child.items) {
            for (const item of child.items) {
                const result = find(child, item)
                if (result) {
                    return result
                }
            }
        }
        return null
    }

    return find(null, root)
}
type BuilderProps = {
    className?: string
    root: any
    onChange?: any
    renderNode: (node: any, onRemove: any) => {}
}
type NodesProps = {
    className?: string
    nodes: any[]
    root: any
    onChange: any
    renderNode: (node: any, onRemove: any) => {}
}
type NodeProps = {
    node: any
    nodes: any[]
    root: any
    onChange: any
    renderNode: (node: any, onRemove: any) => {}
}
export default Builder